import { useState, useEffect, useCallback } from 'react';
import { GroupReservationId, RoomInvitationId } from '@streem/domain-id';
import { HostRoomSession } from '@streem/sdk-core';
import { APIError, StreemAPI } from '@streem/api';
import {
    AcceptTermsAndConditions,
    customerRedirectUrlHandler,
    CustomerRedirectUrlStatuses,
    GetStarted,
    HostInCall,
    Lobby,
} from '@streem/sdk-react';
import { AppText, Button, Modal, Row, Subheader } from '@streem/ui-react';
import { useDetectRtc } from '../hooks/use_detect_rtc';
import { useGlobalStore } from '../hooks/use_global_context';
import appLogger from '../util/app_logger';
import { CallStatusTypes } from '../util/types';
import { LoadingPage } from './loading_page';

export const CustomerViewPage = (): JSX.Element => {
    const { appStore, sdkStore } = useGlobalStore();
    const [showCouldNotConnectModal, setShowCouldNotConnectModal] = useState<boolean>(false);
    const [showCancelButton, setShowCancelButton] = useState<boolean>(false);
    // Note: the syntax for setting the value of state to a function is not what you would intuit:
    // https://react.dev/reference/react/useState#im-trying-to-set-state-to-a-function-but-it-gets-called-instead
    const [handleTryAgain, setHandleTryAgain] = useState<() => void>(undefined);
    const [isRestartingRoom, setIsRestartingRoom] = useState<boolean>(false);
    const [isLoadingTCs, setIsLoadingTCs] = useState<boolean>(false);
    const [callStatus, setCallStatus] = useState<CallStatusTypes>(CallStatusTypes.DEFAULT);
    const [roomInvitationId, setRoomInvitationId] = useState<RoomInvitationId | undefined>(
        undefined,
    );
    const userSession = sdkStore.userSession.current;
    const hostRoomSession = sdkStore.roomSession?.current as HostRoomSession;
    const companySettings = appStore.companySettings;
    const remoteStreemConfigDetails = appStore.remoteStreemConfigDetails;
    const loadingMessage = companySettings?.expertAvailabilityEnabled
        ? 'Validating reservation'
        : 'Validating invitation';

    const restartRoom = useCallback(async () => {
        if (isRestartingRoom) {
            appLogger.debug('Already restarting room:', appStore.roomId);
            return;
        }
        try {
            const { room } = await StreemAPI.rooms.getRoom(appStore.roomId);
            const refreshedRoomSession = await userSession.startRemoteStreem({
                ...remoteStreemConfigDetails,
                role: 'LOCAL_CUSTOMER',
                roomId: appStore.roomId,
                callConfig: {
                    faceToFaceModeEnabled: companySettings.faceToFaceModeEnabled,
                    experimentalFeatures: false,
                    videoPlatformNetworkAdaptationEnabled:
                        companySettings?.chimeNetworkAdaptationEnabled,
                    chimeLowBandwidthOptimizationEnabled:
                        companySettings?.chimeLowBandwidthOptimizationEnabled ?? false,
                },
            });

            const activeRoomInvitations = room?.roomInvitations.filter(
                roomInvitation =>
                    roomInvitation.fromUserSid === appStore.userSid &&
                    roomInvitation.toUserSid === remoteStreemConfigDetails?.withUserId.toString() &&
                    (roomInvitation.status === 'STATUS_PENDING' ||
                        roomInvitation.status === 'STATUS_ACCEPTED'),
            );

            if (activeRoomInvitations.length > 0) {
                await refreshedRoomSession.createHostMediaTracks();
                setCallStatus(CallStatusTypes.IN_CALL);
            } else {
                setCallStatus(CallStatusTypes.CALL_LOBBY);
            }
        } catch (error) {
            appLogger.error('Failed to restart room: ', appStore.roomId, error);
            if (companySettings?.expertAvailabilityEnabled) {
                customerRedirectUrlHandler(
                    companySettings?.endRedirectUrl,
                    appStore.roomId,
                    appStore.groupReservationSid,
                    CustomerRedirectUrlStatuses.CALL_RESTART_FAILED,
                );
            }
            setCallStatus(CallStatusTypes.TS_AND_CS);
        } finally {
            setIsRestartingRoom(false);
        }
    }, [
        appStore.roomId,
        appStore.userSid,
        appStore.groupReservationSid,
        companySettings?.expertAvailabilityEnabled,
        companySettings?.faceToFaceModeEnabled,
        companySettings?.endRedirectUrl,
        companySettings?.chimeNetworkAdaptationEnabled,
        companySettings?.chimeLowBandwidthOptimizationEnabled,
        remoteStreemConfigDetails,
        isRestartingRoom,
        userSession,
    ]);

    useDetectRtc();

    useEffect(() => {
        // The roomId doesn't get set on the AppStore until the user accepts the terms and conditions
        if (callStatus === CallStatusTypes.DEFAULT) {
            if (!appStore.roomId) {
                if (companySettings?.expertAvailabilityEnabled) {
                    // We skip the Get Started page when using Click-to-Video
                    setCallStatus(CallStatusTypes.TS_AND_CS);
                } else {
                    setCallStatus(CallStatusTypes.GET_STARTED);
                }
            } else {
                if (hostRoomSession) appLogger.warn('HostRoomSession already exists on refresh');
                // Once a roomId has been set, we can direct our users to the Lobby or In Call on refresh
                setIsRestartingRoom(true);
                restartRoom().catch(error => appLogger.error('Failed to restart room: ', error));
            }
        }
    }, [
        companySettings?.expertAvailabilityEnabled,
        callStatus,
        appStore.roomId,
        hostRoomSession,
        restartRoom,
    ]);

    useEffect(() => {
        // if the customer refreshes while the call is ringing and the expert has answered, put them in the call
        const remoteParticipantStatusSubscription =
            hostRoomSession?.room?.remoteParticipantStatus.subscribe(status => {
                if (status === 2 && callStatus === CallStatusTypes.CALL_LOBBY) {
                    restartRoom().catch(error =>
                        appLogger.error('Failed to restart room: ', error),
                    );
                }
            });
        return () => {
            if (remoteParticipantStatusSubscription) {
                remoteParticipantStatusSubscription.unsubscribe();
            }
        };
    }, [callStatus, hostRoomSession, restartRoom]);

    const startStreemCall = async () => {
        setRoomInvitationId(await hostRoomSession.sendInvitation());
        setCallStatus(CallStatusTypes.IN_CALL);
    };

    const createHostMediaTracks = async () => {
        try {
            await hostRoomSession.createHostMediaTracks();
        } catch (error) {
            appLogger.error(
                'Failed to create host media tracks for customer, stuck in lobby: ',
                error,
            );
            setHandleTryAgain(() => createHostMediaTracks);
            setShowCouldNotConnectModal(true);
        }
    };

    const onAcceptTermsAndConditions = async () => {
        try {
            setIsLoadingTCs(true);
            await appStore.acceptLatestTCs();
        } catch (error) {
            appLogger.error('Failed to accept terms and conditions: ', error);
            setHandleTryAgain(() => onAcceptTermsAndConditions);
            setShowCouldNotConnectModal(true);
            return;
        } finally {
            setIsLoadingTCs(false);
        }

        try {
            await userSession.startRemoteStreem({
                ...remoteStreemConfigDetails,
                role: 'LOCAL_CUSTOMER',
                // Returns existing roomSession if roomId is matched
                roomId: appStore.roomId,
                callConfig: {
                    faceToFaceModeEnabled: companySettings.faceToFaceModeEnabled,
                    experimentalFeatures: false,
                    chimeLowBandwidthOptimizationEnabled:
                        companySettings.chimeLowBandwidthOptimizationEnabled,
                    videoPlatformNetworkAdaptationEnabled:
                        companySettings.chimeNetworkAdaptationEnabled,
                },
            });
            setCallStatus(CallStatusTypes.CALL_LOBBY);
        } catch (error) {
            appLogger.error('Failed to start remote streem: ', error);
            /* This case is specific to Click-to-Video: a remote streem can not start if the group reservation has
             * expired (indicated by a 432 error). In this case, we redirect the customer to the custom redirect url
             * provided through the company settings.
             */
            if (
                error instanceof APIError &&
                error.response.status === 432 &&
                companySettings.expertAvailabilityEnabled
            ) {
                customerRedirectUrlHandler(
                    companySettings?.endRedirectUrl,
                    appStore.roomId,
                    appStore.groupReservationSid,
                    CustomerRedirectUrlStatuses.GROUP_RESERVATION_EXPIRED,
                );
            } else {
                setHandleTryAgain(() => onAcceptTermsAndConditions);
                setShowCouldNotConnectModal(true);
            }
        }
    };

    const exitToLobby = () => {
        // TODO: stop sending data tracks to Twilio if call is rejected
        // await this.config.trackProvider.disconnect() [sdk-core/media_controller.ts]
        setCallStatus(CallStatusTypes.CALL_LOBBY);
        setHandleTryAgain(() => startStreemCall);
        setShowCancelButton(true);
        setShowCouldNotConnectModal(true);
    };

    return (
        <>
            {showCouldNotConnectModal && (
                <CouldNotConnectModal
                    handleCancel={() => {
                        setShowCouldNotConnectModal(false);
                    }}
                    handleTryAgain={async () => {
                        setShowCouldNotConnectModal(false);
                        await handleTryAgain();
                    }}
                    showCancelButton={showCancelButton}
                />
            )}
            {callStatus === CallStatusTypes.GET_STARTED && (
                <GetStarted
                    handleGetStarted={() => setCallStatus(CallStatusTypes.TS_AND_CS)}
                    invitationDetails={appStore.invitationDetails}
                    companySettings={companySettings}
                    hideStreemBranding={companySettings.hideStreemBranding}
                />
            )}
            {callStatus === CallStatusTypes.TS_AND_CS && (
                <AcceptTermsAndConditions
                    companySettings={companySettings}
                    isLoading={isLoadingTCs}
                    handleAcceptLatestTCs={onAcceptTermsAndConditions}
                    handleOnDeclineTCs={() => {
                        if (companySettings?.expertAvailabilityEnabled) {
                            customerRedirectUrlHandler(
                                companySettings?.endRedirectUrl,
                                hostRoomSession?.roomId?.toString(),
                                appStore.groupReservationSid,
                                CustomerRedirectUrlStatuses.DECLINED_TCS,
                            );
                        }
                    }}
                />
            )}
            {/* A user exits the lobby when callStatus === 'PENDING' */}
            {callStatus === CallStatusTypes.CALL_LOBBY && (
                <Lobby
                    userSession={userSession}
                    hostRoomSession={hostRoomSession}
                    invitationDetails={appStore.invitationDetails}
                    companySettings={companySettings}
                    startCall={startStreemCall}
                    onInit={createHostMediaTracks}
                />
            )}
            {/* They return to the Lobby if callStatus !== 'ACCEPTED' || callStatus !== 'PENDING'*/}

            {callStatus === CallStatusTypes.IN_CALL && (
                <HostInCall
                    companyInfo={{
                        companyName: appStore.companyName,
                        agentName: appStore.agentName,
                        agentAvatar: appStore.agentAvatar,
                    }}
                    roomSession={hostRoomSession}
                    companySettings={companySettings}
                    roomInvitationId={roomInvitationId}
                    groupReservationId={
                        appStore.groupReservationSid
                            ? new GroupReservationId(appStore.groupReservationSid)
                            : undefined
                    }
                    exitToLobby={exitToLobby}
                />
            )}

            {isRestartingRoom && <LoadingPage message={loadingMessage} />}
        </>
    );
};

interface CouldNotConnectModalProps {
    handleCancel: () => void;
    handleTryAgain: () => void;
    showCancelButton: boolean;
}
const CouldNotConnectModal = ({
    handleCancel,
    handleTryAgain,
    showCancelButton,
}: CouldNotConnectModalProps) => {
    return (
        <Modal
            closeDelayMS={200}
            hideCloseButton={true}
            closeOnOverlayClick={false}
            isOpen={true}
            cardProps={{
                decoratorPosition: undefined,
            }}
            overlayColor="greyA50"
            mobile
        >
            <Row mb={24}>
                <Subheader data-testid="call-declined-header" size="large" bold>
                    Call Could Not Connect
                </Subheader>
            </Row>
            <Row mb={42}>
                <AppText data-testid="call-declined-message" as="p" color="grey70">
                    Your Streem call could not connect. Try connecting again or reach out to the
                    expert you&apos;re trying to connect with.
                </AppText>
            </Row>
            <Row justifyContent="flex-end">
                {showCancelButton && (
                    <Button ml={4} variant="secondary" mobile onClick={handleCancel}>
                        Cancel
                    </Button>
                )}
                <Button ml={4} mobile onClick={handleTryAgain}>
                    Try Again
                </Button>
            </Row>
        </Modal>
    );
};
